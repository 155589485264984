@import "../../styles/variables";

.navBar {
  background: var(--main-background-color);
  height: 60px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;


  @media (min-width: $tablet-width) {
    margin-bottom: 16px;
    height: 65px;
  }

  .container {
    @include centered;
    display: flex;
    width: $max-width;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      overflow: hidden;

      @media (min-width: $desktop-width) {
        width: 240px;
      }

      .navMenu {
        margin-right: 12px;

        .menuIcon {
          font-size: 20px;
          padding-top: 4px;
        }

        .logoIcon {
          width: 30px;
          border-radius: 4px;
        }

        @media (min-width: $desktop-width) {
          //display: none;
        }
      }
    }

    .right {
      //display: none;

      @media (min-width: $tablet-width) {
        //width: 240px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .createButton {
        margin-right: 16px;
        width: 100px;
      }

      .username {
        display: none;
        @media (min-width: $tablet-width) {
          margin-right: 16px;
          display: block;
        }
      }

      .profileImage {
        width: 40px;
        height: 40px;
        max-width: 40px;
        overflow: hidden;
        border-radius: 20px;
        object-fit: cover !important;
      }
    }

  }
}

.hideNav {
  display: none;

  @media (min-width: $tablet-width) {
    display: flex;
  }
}

.searchMore {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
