@import "src/styles/variables";

.CalendarEventItem {

  .startOfYear {
    text-align: center;
    color: var(--primary-text-color);
    font-size: large;
    font-weight: bold;
  }

  .dateHeader {
    font-size: large;
    font-weight: bold;
    color: var(--primary-text-color);
    border-bottom-width: 1px;
    border-bottom-color: lightgrey;
    border-bottom-style: solid;
    padding: 16px;
    text-align: center;
    background: var(--main-hover-color);

    @media (min-width: $tablet-width) {
      border-bottom-width: 0px;
      margin-bottom: 8px;
      background: transparent;
    }
  }

  .event {
    @include card-border-around-element;
    cursor: pointer;

    @media (min-width: $tablet-width) {
      margin-bottom: 16px;
    }


    .eventHeader {
      display: flex;
      justify-content: space-between;
      font-size: small;

      .repeatTag {
        margin-left: 8px;
        text-transform: lowercase !important;
      }

      .completedTag {
        margin-right: 0;
        font-size: small;
      }
    }

    .eventBody {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      .leftContainer {
        .title {
          color: var(--primary-text-color);
          font-size: medium;
          font-weight: bold;
        }


      }

      .rightContainer {
        .time {
          color: var(--primary-text-color);
          font-size: medium;
          font-weight: bold;
        }
      }
    }

    .eventFooter {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .tags {

        .leftTag {
          font-size: small;
        }
      }

      .calendars {

        .rightTag {
          margin-right: 0;
          margin-left: 8px;
          font-size: small;
        }
      }

    }

  }

}