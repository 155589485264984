@import "../../../../../styles/variables";

.WorkspaceDrawerItem {
  @include border-bottom-element;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 16px;

  .image {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .activeImage {
    border-style: solid;
    border-width: 2px;
    border-color: $primaryColor;
  }

  .names {
    flex: 1;
    padding-left: 12px;
    padding-right: 12px;

    .displayName {
      font-weight: bold;
      color: var(--primary-text-color);
    }
  }

  .actions {

  }
}

.activeWorkspaceDrawerItem {
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: $primaryColor;
}