@import "../../styles/variables";

.hideMenu {
  display: none;
}

.Menu {
  min-height: 300px;
  height: fit-content;
  justify-content: flex-end;
  padding-right: 16px;

  .container {
    @include card-border-around-element;
    padding: 16px;

    .active {
      color: $primaryColor !important;
    }

    .menuItem {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;
      color: var(--primary-text-color);

      &:hover {
        color: $primaryColor !important;
      }

      .icon {
        width: 20px;
      }

      .title {
        flex: 1;
        padding-left: 12px;
      }
    }

    .create {
      //font-size: 20px;
      //font-weight: 700;
      margin-top: 16px;
      width: 100%;
    }
  }

  .themeContainer {
    margin-top: 8px;

    .themeSwitch {
      width: 60px;

    }
  }
}
