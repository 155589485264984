@import "../../../styles/variables";

.CreateCalendarEventScreen {

  .switchContainers {
    display: flex;
    align-items: center;

    .repeatSwitch {
      margin-left: 32px;
    }
  }

  .correct {
    color: $green;
  }

  .incorrect {
    color: red;
  }

  .output {
    color: var(--primary-text-color);
    font-style: italic;
  }

  .dateContainer {
    display: flex;
    align-items: center;

    .timePicker {
      margin-left: 32px;
    }
  }
}

.ExampleDrawer {

  .exampleDrawerTitle {
    font-size: large;
    font-weight: bold;
    color: var(--primary-text-color);
    padding-bottom: 16px;
  }

  .example {
    margin-bottom: 16px;
  }
}