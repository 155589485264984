@import "../../../styles/variables";

.NotificationCard {
  padding: 16px !important;
  display: flex !important;
  align-items: flex-start !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: lightgrey !important;

  @media (min-width: $tablet-width) {
    @include card-border-around-element;
    margin-bottom: 16px !important;
  }

  .image {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }

  .text {
    flex: 1;
    padding-left: 16px;

    .username {
      font-weight: 600;
      color: var(--primary-text-color);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .postImage {
    margin-top: 8px;
    height: 50px;
    width: 50px;

    &:hover {
      cursor: pointer;
    }
  }

  .postWilt {
    @include border-element;
    background: white;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    font-size: 7.5px;
    padding: 2px;
    color: black;

    &:hover {
      cursor: pointer;
    }

    .header {
      display: none;
      height: 0px;
      background: yellow;
      //font-size: 100%;
    }

    .body {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      //white-space: nowrap;
      -webkit-line-clamp: 4;
    }

    .footer {
      display: none;
      height: 0px;
      //background: transparent;
    }
  }

  .follow {
    margin-top: 8px;
  }
}