@import "../../styles/variables";

.SearchableHeader {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: 0px;
  z-index: 1;

  @media (min-width: $tablet-width) {
    top: 64px;
  }

  .headerWhiteboard {
    background: var(--main-background-color);

    @media (min-width: $tablet-width) {
      padding-top: 16px;
    }

    .messageHeader {
      @include card-border-bottom-element;
      padding: 12px 16px;

      @media (min-width: $tablet-width) {
        @include card-border-around-element;
        //margin-top: 12px;
      }

      .topHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .backButton {
          margin-right: 8px;
        }

        .titleHeader {
          flex: 1;
          font-size: 18px;
          font-weight: bold;
          color: var(--primary-text-color);
          text-align: left;
        }

        .remindersButton {
          margin-right: 16px;
        }
      }

      .searchContainer {
        margin-top: 8px;
      }
    }
  }

}
