@import "../../styles/variables";

.ListHeader {
  @include card-border-around-element;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftContainer {

      .goBack {
        margin-right: 8px;
      }

      .title {
        font-size: $medium-font-size;
        font-weight: bold;
        color: var(--primary-text-color);
      }
    }

    .rightContainer {

    }
  }

  .searchContainer {
    margin-top: 16px;
    display: flex;
    align-items: center;

    .advancedFilters {
      margin-left: 16px;
    }
  }

}