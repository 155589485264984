@import "../../../../styles/variables";

.MetricItemCard {
  @include card-border-around-element;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;

  .leftContent {
    display: flex;
    align-items: center;

    .numericValue {
      font-size: medium;
      color: var(--primary-text-color);
    }

    .description {
      margin-left: 12px;
    }
  }
}