@import "../../styles/variables";

.Logo {
  font-weight: 800;
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
  font-size: 18px;

  @media (min-width: $tablet-width) {
    font-size: 22px;
  }

  &:hover {
    cursor: pointer;
  }

  .gif {
    color: $blue;
  }

  .beta {
    margin-left: 8px;
  }
}
