@import "../../../../styles/variables";

.WorkspaceDrawerContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    @include border-bottom-element;
    padding: 12px;
    font-size: large;
    font-weight: bold;
  }

  .body {
    flex: 1;
    //padding: 12px;
  }

  .footer {
    @include border-top-element;
    padding: 12px 12px 24px;

    .footerItem {
      margin-bottom: 16px;
      cursor: pointer;

      &:hover {
        color: $primaryColor;
      }

      .icon {
        font-size: large;
      }

      .title {
        font-size: medium;
        padding-left: 16px;
      }
    }
  }
}