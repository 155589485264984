@import "../../../../styles/variables";

.CalendarDrawerFilters {
  height: 100%;
  display: flex;
  flex-direction: column;

  .formContainer {
    flex: 1;
    padding: 16px;

    .datePicker {
      width: 100%;
    }
  }

  .footer {

    .applyFilters {
      width: 100%;
      height: 60px;
    }
  }
}

.calendarDrawerFilterHeader {
  display: flex;
  align-items: center;
  @include border-bottom-element;
  padding: 16px;

  .close {

  }

  .title {
    flex: 1;
    text-align: left;
    padding-left: 8px;
  }

  .reset {

  }
}