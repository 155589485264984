@import "../../../../../styles/variables";

.MetricChildrenItem {
  @include card-border-around-element;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .leftContent {
    display: flex;
    align-items: center;

    .numericValue {
      font-size: medium;
      font-weight: bold;
      color: var(--primary-text-color);
    }

    .description {
      margin-left: 12px;
    }
  }

  .rightContent {

  }
}