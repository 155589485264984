@import "../../styles/variables";

.LandingScreen {

  .body {

    .preview {
      width: 400px;
      height: 400px;
      @include card-border-around-element;
      display: flex;
      flex-direction: column;
      background: white;

      .articleContainer {
        //flex: 1;
        //background: green;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;

        .articleCard {
          border-style: solid !important;
          border-width: 1px !important;
          border-color: lightgrey !important;
          //background: turquoise;
          width: 86%;
          //height: 88%;
          display: flex;
          //align-items: center;
          padding: 8px;
          border-radius: 4px;

          .articleContent {
            flex: 1;
            color: black;

            .sourceContainer {
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              .sourceImage {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                //background: red;
              }

              .sourceTitle {
                color: black;
                margin-left: 8px;
              }
            }

            .articleTitle {
              font-weight: 500;
            }
          }

          .articleImage {
            width: 100px;
            height: 100px;
            //background: red;
            border-radius: 4px;
            margin-left: 8px;
          }
        }
      }

      .reactionContainer {
        flex: 1;
        //background: red;
        display: flex;
        justify-content: center;
        align-items: center;

        .reactionCard {
          background: turquoise;
          width: 84%;
          //height: 70%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }

    .preview1 {
      width: 400px;
      height: 400px;
      @include card-border-around-element;
      display: flex;
      background: white;

      .articleContainer {
        flex: 1;
        //background: green;
        display: flex;
        justify-content: center;
        align-items: center;

        .articleCard {
          //background: turquoise;
          width: 94%;
          border-radius: 4px;
          //height: 100%;
        }
      }

      .reactionContainer {
        flex: 1;
        //background: red;
        display: flex;
        justify-content: center;
        align-items: center;

        .reactionCard {
          //background: turquoise;
          width: 80%;
          //height: 70%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }

  .test {
    position: relative;
    width: 320px;
    height: 320px;

    .item1 {
      position: absolute;
      width: 50%;
      height: 50%;
      bottom: 0;
      overflow: hidden;
      text-align: center;

      .dot {
        background: var(--tertiary-text-color);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin: 8px;
        text-align: center;
      }
    }

    .item2 {
      position: absolute;
      width: 50%;
      height: 50%;
      top: 0;
      right: 0;
      overflow: hidden;
      text-align: center;

      .dot {
        background: var(--tertiary-text-color);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin: 8px;
        text-align: center;
      }
    }

    .item3 {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      //margin: 40px;
      //left: 20%;
      padding: 48px;

      .image {
        background: purple;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}