@import "../../styles/variables";

.ViewHeader {
  @include card-border-around-element;
  display: flex;
  align-items: center;

  .goBack {
    margin-right: 16px;

    @media (min-width: $tablet-width) {
      display: block;
    }
  }

  .nameContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
      font-size: 18px;
      margin-right: 16px;
    }
  }

}