@import "../../../styles/variables";

.HomeCalendarScreen {
  padding-bottom: 16px;

  @media (min-width: $tablet-width) {
    padding-bottom: 0px;
  }

  .cally {
    //height: 100vw !important;

    @media (min-width: $tablet-width) {
      //height: 100% !important;
    }

  }


}

.calendarControlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include card-border-around-element;

  @media (min-width: $tablet-width) {
    margin-top: 16px;
  }

  .calendarControlTitle {
    font-size: large;
    font-weight: bold;
    color: var(--primary-text-color);
  }
}