@import "../../../../../styles/variables";

.ViewListDocumentItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .text {
    font-size: medium;
  }

  .confirmButton {
    background: $green;
    border-color: $green;
    margin-left: 16px;
  }

  .cancelButton {
    background: $red;
    border-color: $red;
    margin-left: 16px;
  }

  .editIcon {

  }
}