
.CreateApiModal {

  .title {
    font-size: large;
    color: var(--primary-text-color);
    margin-bottom: 16px;
  }

  .saveButton {
    width: 100%;
  }
}