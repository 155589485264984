@import "../../../../styles/variables";

.MetricsTab {

  .sumContainer {
    @include border-around-element;
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    color: var(--primary-text-color);
    margin-top: 16px;
  }

  .addMetricContainer {
    padding: 16px;
    @include mobile-border-bottom-element;

    @media (min-width: $tablet-width) {
      padding: 16px 0px;
    }

    .addMetricButton {
      width: 100%;
    }
  }
}

.MetricsTabDrawer {

  .metricsTabDrawerTitle {
    font-size: large;
    font-weight: bold;
    color: var(--primary-text-color);
    padding-bottom: 16px;
  }
}