@import "../../../../../styles/variables";

.CalendarTagItem {
  @include card-border-around-element;
  color: var(--primary-text-color);
  cursor: pointer;

  @media (min-width: $tablet-width) {
    margin-bottom: 16px;
  }
}