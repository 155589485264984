
.CreateFlowDocumentScreen {

  .nodesTitle {
    font-size: medium;
    color: var(--primary-text-color);
    margin-bottom: 24px;
  }

  .addApi {
    margin-top: 8px;
  }

}