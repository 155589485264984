
.CreateMetricScreen {

  .inputNumbers {
    display: flex;

    .ranking {
      margin-left: 32px;
    }
  }
}