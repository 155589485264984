@import "../../styles/variables";

.ListLayout {

  .skeletonItem {
    @include border-bottom-element;
    width: 100%;

    @media (min-width: $tablet-width) {
      border-bottom-style: none;
      margin-bottom: 12px;
      border-radius: 4px;
    }

    > span {
      height: inherit;
    }

  }

  .skeletons {


    @media (min-width: $tablet-width) {
      padding-top: 12px;
    }
  }

  .spinningContainer {
    @include spinning-container;
  }
}