@import "../../../../styles/variables";

.CalendarItem {
  @include card-border-around-element;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .title {
    flex: 1;
    font-size: medium;
    color: var(--primary-text-color);
  }

  .private {

  }
}