@import "../styles/variables";

.App {
  background: var(--main-background-color);

  .appBody {

    //.mainContent {
    //  height: calc(100vh - 1px);
    //
    //  @media (min-width: $tablet-width) {
    //    min-height: calc(100vh - 136px);
    //  }
    //
    //  @media (min-width: $desktop-width) {
    //    //min-height: initial;
    //  }
    //
    //}

    .mainContainer {
      display: flex;
      min-height: calc(100vh - 60px);
      max-width: $max-width;
      @include centered;
      //padding-left: 16px;
      //padding-right: 16px;

      @media (min-width: $tablet-width) {
        min-height: calc(100vh - 134px);
      }

      .menuMainContainer {
        display: none;
        width: $side-content-width;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .contentMainContainer {
        flex: 1;
        display: flex;

        .centerMainContainer {
          flex: 1;
        }

        .rightMainContainer {
          display: none;
          width: $side-content-width;

          @media (min-width: $tablet-width) {
            display: block;
          }
        }
      }

    }
  }
}
