@import "../../../styles/variables";

.MessageItem {
  @include border-top-element;
  display: flex;
  //align-items: center;
  padding: 16px;

  .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .content {
    padding-left: 16px;

    .header {
      .name {
        font-weight: bold;
        color: var(--primary-text-color);
      }

      .timeStamp {
        margin-left: 8px;
        font-size: 12px;
      }
    }

    .messageBody {
      margin-top: 8px;
      border-radius: 4px;
      max-width: 100%;
      min-width: 260px;

      @media (min-width: $tablet-width) {
        width: 340px;
      }
    }
  }
}