:root {
  --main-bg-color: #242c3c;

  --dark-background-color: #141414;
  --dark-secondary-background-color: #141414;
  --dark-divider-color: rgb(56, 68, 77);
  --dark-card-color: rgb(25, 39, 52);
  --dark-border-color: rgb(56, 68, 77);
  --dark-primary-text-color: white;
  --dark-secondary-text-color: white;
  --dark-tertiary-text-color: #8899a6;
  --dark-skeleton-color2: linear-gradient(90deg, #21405a 25%, #2d457c 37%, #21405a 63%);
  --dark-skeleton-color: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
  --dark-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(347deg) brightness(104%) contrast(101%);
  --dark-gradient: linear-gradient(0deg, rgba(20,20,20,1) 50%, rgba(20,20,20,0.7987570028011204) 70%, rgba(20,20,20,0.6530987394957983) 81%, rgba(20,20,20,0) 100%);
  --dark-home-divider-color: #484848;
  --dark-hover-color: #303030 25%;

  --light-background-color: rgb(255, 255, 255);
  --light-secondary-background-color: rgb(255, 255, 255);
  --light-divider-color: rgb(255, 255, 255);
  --light-card-color: rgb(255, 255, 255);
  --light-border-color: lightgray;
  --light-primary-text-color: black;
  --light-secondary-text-color: black;
  --light-tertiary-text-color: #605e5e;
  --light-skeleton-color: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  --light-filter: invert(0%) sepia(4%) saturate(16%) hue-rotate(113deg) brightness(94%) contrast(103%);
  --light-gradient: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7987570028011204) 70%, rgba(255,255,255,0.6530987394957983) 81%, rgba(255,255,255,0) 100%);
  --light-home-divider-color: #e6e6e6;
  --light-hover-color: #e6e6e6;

  --main-background-color: var(--light-background-color);
  --main-secondary-background-color: var(--light-secondary-background-color);
  --main-divider-color: var(--light-divider-color);
  --main-card-color: var(--light-card-color);
  --main-border-color: var(--light-border-color);
  --primary-text-color: var(--light-primary-text-color);
  --secondary-text-color: var(--light-secondary-text-color);
  --tertiary-text-color: var(--light-tertiary-text-color);
  --main-skeleton-color: var(--light-skeleton-color);
  --main-filter: var(--light-skeleton-color);
  --main-gradient: var(--light-gradient);
  --main-home-divider-color: var(--light-home-divider-color);
  --main-hover-color: var(--light-hover-color);
}

body {
  margin: 0;
  font-family: 'Roboto', system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color) !important;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  background-color: var(--main-background-color);
  background: var(--main-background-color);
}

.ant-modal-header {
  padding: 0px !important;
  padding-top: 0px !important;
  background: var(--main-background-color) !important;
}

.ant-modal-body {
  background: var(--main-background-color);
}

.ant-modal-footer {
  padding: 0px !important;
  padding-bottom: 0px !important;
  text-align: left !important;
}

.ant-tabs-bar {
  margin: 0px !important;
}

.ant-popover-inner-content {
  padding: 4px 12px !important;
}

.ant-typography-edit-content {
  left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ql-editor {
  font-size: 16px !important;
  color: black !important;
}

.ql-tooltip {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 300px !important;
}

.ql-tooltip-arrow {
  margin-left: 0 !important;
}

.ql-hidden {
  display: table-cell !important;
}

.ant-list-split .ant-list-header {
  border-bottom: 0px solid #f0f0f0 !important;
}

.ant-skeleton-title {
  margin: 0px !important;
}

.ant-skeleton-paragraph {
  margin: 0px !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lazy-load-image-loaded {
  width: 100% !important;
  height: 100% !important;
}

.rbc-today {
  background: #6992b7 !important;
  color: red !important;
  /*border-width: 4px;*/
  /*border-style: solid;*/
  /*border-color: green;*/
}

.rbc-off-range-bg {
  background: var(--main-hover-color) !important;
}

.rbc-event-content {
  /*background-color: red !important;*/
}

.rbc-event {
  background-color: #0095f6 !important;
}