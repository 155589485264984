@import "../../styles/variables";

.GridLayout {

  .infiniteScrollGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //grid-auto-rows: 100vw * 0.5;
    grid-column-gap: 0;
    grid-row-gap: 0;
    padding-bottom: 16px;
    min-height: 400px !important;
    height: auto !important;

    @media (min-width: $tablet-width) {
      padding-top: 12px;
      grid-row-gap: 0.5vw;
      grid-column-gap: 0.5vw;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 264px;
    }

    .spinningContainer {
      @include spinning-container;
    }

    .skeletonItem {
      grid-column: span 1;
      width: 100%;
      height: 50vw;
      bottom: 0;

      &:nth-child(odd) {
        padding-right: 2px;
        margin-bottom: 2px;
      }

      @media (min-width: $tablet-width) {
        height: 100%;

        &:nth-child(odd) {
          padding-right: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .emptyView {
    grid-column: span 3;
  }
}