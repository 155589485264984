@import "../../../../styles/variables";

.CalendarTagsTab {


  .addCalendarTagContainer {
    @include mobile-border-bottom-element;
    padding: 16px;

    @media (min-width: $tablet-width) {
      padding: 16px 0px;
    }

    .addCalendarTagButton {
      width: 100%;
    }

  }

}