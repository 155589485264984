@import "../../../styles/variables";

.Main {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    padding-top: 64px;
    @include centered;
    max-width: $max-width;
    flex-direction: row;
  }

  .textContainer {
    flex: 1;
    padding-bottom: 24px;
    color: var(--primary-text-color);

    .title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 24px;
      text-transform: uppercase;
      //color: black;

      .shipping {
        background: linear-gradient(180deg,rgba($blue,0) 50%, rgba($blue,0.7) 50%);
      }

      @media (min-width: $tablet-width) {
        font-size: 51px;
      }
    }

    .subtitle {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 24px;

      .highlight {
        color: $blue;
        font-weight: 800;
      }

      @media (min-width: $tablet-width) {
        font-size: 32px;
      }
    }

    .actions {
      display: none;

      @media (min-width: $tablet-width) {
        display: initial;
      }

      .actionButton {

      }

    }

  }

  .imageContainer {
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;

    @media (min-width: $tablet-width) {
      padding-left: 32px;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }
}
