
.ViewFlowDocumentScreen {
  margin-top: 16px;

  .actionBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .collapseButton {
      padding-left: 0px;
    }
  }
}