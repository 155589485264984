@import "../../styles/variables";

.none {
  display: none;
}

.BottomNavBar {
  background: var(--main-background-color);
  height: 60px;
  @include border-top-element;
  display: flex;
  align-items: center;

  @media (min-width: $tablet-width) {
    display: none;
  }

  .active {
    color: $blue !important;
  }

  .item {
    flex: 1;
    display: flex;
    justify-content: center;

    .icon {
      font-size: 24px;
    }
  }
}