@import "../../../../styles/variables";

.WorkspacesItem {
  @include card-border-around-element;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    margin-top: 16px;
  }

  .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .names {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;

    .displayName {
      font-size: 16px;
      font-weight: bold;
      color: var(--primary-text-color);
    }
  }

  .actions {

  }
}