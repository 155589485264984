@import "../../../../styles/variables";

.ReadingComp {
  //

  .highlightWord {
    color: var(--primary-text-color);
    //font-weight: bold;
  }

  .highlightWord2 {
    color: var(--primary-text-color);
    background: linear-gradient(180deg,rgba($blue,0) 50%, rgba($blue,0.7) 50%);
    //font-weight: bold;
  }

  .highlightWord3 {
    color: var(--primary-text-color);
    //font-weight: bold;
  }

  .slider {
    width: 300px;
    margin-top: 64px;
  }
}