@import "../../../../styles/variables";

.CalendarView {
  padding-bottom: 16px;
  
  @media (min-width: $tablet-width) {
    padding-top: 16px;
  }

  .callyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: $tablet-width) {
      padding-top: 0px;
    }

    .title {
      font-size: large;
      color: var(--primary-text-color);
    }
  }

}