@import "../../../styles/variables";

.ListCalendarScreen {
  padding-bottom: 16px;

  @media (min-width: $tablet-width) {
    padding-top: 16px;
  }

  .selectCalendar {
    text-align: center;
    font-style: italic;
    margin-bottom: 16px;
    color: var(--primary-text-color);
  }
}
