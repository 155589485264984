$blue: #0095f6;
$red: #ED1D24;
$green: #2ECC71;
$summary-green: rgba(46,204,113, 0.8);
$orange: #ffa500;
$backgroundGray: #FAFAFA;
$purple: #9b59b6;

$primaryTextColor: black;
$secondaryTextColor: rgba(0,0,0,0.8);
$tertiaryTextColor: rgba(0,0,0,0.6);
$title-text-color: rgb(20, 23, 26);

$logoRed: $red;
$logoGreen: $green;
$logoBlue: $blue;
$logoYellow: $orange;

$primaryColor: $blue;

$medium-font-size: 18px;

$filterRed: invert(47%) sepia(40%) saturate(4817%) hue-rotate(335deg) brightness(91%) contrast(99%);
$filterGreen: invert(70%) sepia(71%) saturate(515%) hue-rotate(85deg) brightness(88%) contrast(80%);
$filterBlue: invert(55%) sepia(62%) saturate(877%) hue-rotate(177deg) brightness(86%) contrast(99%);
$filterYellow: invert(71%) sepia(72%) saturate(3423%) hue-rotate(360deg) brightness(105%) contrast(108%);
$filterPurple: invert(45%) sepia(9%) saturate(3566%) hue-rotate(238deg) brightness(90%) contrast(82%);

$chat-blue: #3498DB;
$chat-gray: #ababab;

$content-width: 800px;
$border-radius: 4px;

$mobile-width: 600px;
$tablet-width: 800px;
$desktop-width: 1025px;
$side-content-width: 280px;

$max-width: 1040px;
$post-width: 500px;

$home-feed-card-margin-bottom: 16px;

@mixin centered {
  margin-left: auto;
  margin-right: auto;
}

@mixin card-border-bottom-element {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
  padding: 12px 16px;
}

@mixin border-top-element {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: lightgrey;
}

@mixin border-bottom-element {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
}

@mixin mobile-border-bottom-element {
  @include border-bottom-element;
  @media (min-width: $tablet-width) {
    border-bottom-style: none;
  }
}



@mixin spinning-container {
  text-align: center;
  grid-column: span 2;
  padding-top: 16px;


  @media (min-width: $tablet-width) {
    grid-column: span 3;
  }
}

@mixin card-border-around-element {
  @include card-border-bottom-element;

  @media (min-width: $tablet-width) {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: lightgrey !important;
    border-radius: $border-radius !important;
  }
}

@mixin border-around-element {

  @media (min-width: $tablet-width) {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: lightgrey !important;
    border-radius: $border-radius !important;
    padding: 12px 16px;
  }
}

@mixin border-element {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: lightgrey !important;
}

@mixin border-top-bottom {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: lightgrey !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: lightgrey !important;
}