
.TenorGifs {
  //padding-left: 2px;

  .gifPreviewContainer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    margin-top: 16px;
    text-align: center;

    .searchImageContainer {
      padding: 24px;

      .searchImage {
        width: 100%;
        border-radius: 8px;
      }

    }

    .preview {
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      //padding-bottom: 32px;

      .gifPreviewImage {
        width: 100%;
        border-radius: 4px;
      }

      .sendGifButton {
        margin-top: 8px;
        width: 200px;
        margin-bottom: 24px;
      }
    }


  }
}