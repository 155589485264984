@import "../../styles/variables";

.ChatScreen {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: $tablet-width;
  height: calc(100vh - 1px);
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    position: relative;
    height: calc(100vh - 108px);
    margin-top: 16px;
    @include card-border-around-element;
  }

  .headerContainer {
    @include card-border-bottom-element;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .backButton {
      margin-right: 16px;
    }

    .centerContainer {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  .divider {
    height: 1px;
    background: lightgrey;
    width: 100%;
  }

  .dangerButton {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .messagesContainer {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    overflow-y: auto !important;
    padding-bottom: 8px;

    .spinningContainer {
      text-align: center;
      padding-top: 16px;
    }

    .item {
      //background: orange;
      height: auto;
    }
  }

  .inputContainer {
    min-height: 50px;
    border-top-color: lightgrey;
    border-top-width: 1px;
    border-top-style: solid;
    height: auto;
    display: flex;
    align-items: center;
    padding: 8px;

    .sendingDataContainer {
      border-radius: 16px;
      border-color: lightgrey;
      border-width: 1px;
      border-style: solid;
      flex: 1;
      width: calc(100% - 200px);

      .attachmentContainer {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .addButton {
      font-size: 22px;
      color: $blue;
      margin-right: 8px;
    }

    .textArea {
      //margin-left: 8px;
      font-size: 16px;
      border-radius: 16px;
    }

    .sendButton {
      margin-left: 8px;
      font-size: 22px;
      color: $blue;
    }
  }
}

.gifDrawer {
  //width: 600px !important;
}

.gifContainer {
  @include centered;
  max-width: 1000px;
  //width: 600px !important;

  .gifPreviewContainer {
    height: calc(100vh - 108px);
    overflow-y: auto;
    margin-top: 16px;
    text-align: center;


    .preview {
      width: 100%;
      padding-right: 16px;
      padding-bottom: 32px;

      .gifPreviewImage {
        width: 100%;
        border-radius: 4px;
      }

      .sendGifButton {
        margin-top: 8px;
        width: 200px;
      }
    }


  }
}