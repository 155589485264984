@import "../../../../styles/variables";

.ViewListDocumentScreen {

  .tabContainer {

    .listContainer {

      .completedContainer {
        padding-bottom: 16px;
      }

      .actionsContainer {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .completedListContainer {
      @media (min-width: $tablet-width) {
        padding-top: 16px;
      }

      .completedListItem {

        @media (min-width: $tablet-width) {
          margin-bottom: 16px;
        }
      }
    }
  }

}