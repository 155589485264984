@import "../../../styles/variables";

.EditCalendarEventScreen {

  .switchContainers {
    display: flex;
    align-items: center;

    .repeatSwitch {
      margin-left: 32px;
    }
  }

  .correct {
    color: $green;
  }

  .incorrect {
    color: red;
  }

  .dateContainer {
    display: flex;
    align-items: center;

    .timePicker {
      margin-left: 32px;
    }
  }

}