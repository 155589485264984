@import "../../styles/variables";

.CreateLayout {
  @include border-around-element;

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom-style: solid;
    border-bottom-color: lightgrey;
    border-bottom-width: 1px;

    @media (min-width: $tablet-width) {
      padding: 0px;
      border-bottom-style: none;
    }

    .goBack {
      margin-right: 16px;

      @media (min-width: $tablet-width) {
        display: block;
      }
    }

    .name {
      font-size: 18px;
      flex: 1;
    }
  }

  .children {
    padding: 16px;

    @media (min-width: $tablet-width) {
      padding: 16px 0px 0px;
    }
  }

  .footer {
    padding: 16px 0px;

    @media (min-width: $tablet-width) {
      margin-top: 40px;
    }
  }
}