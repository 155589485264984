
.CreateMetricItemScreen {

  .valueInputNumber {
    width: 140px;
  }

  .description {
    width: 240px;
  }
}