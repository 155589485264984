@import "../../../../styles/variables";

.MetricsItem {
  grid-column: span 1;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
  border-bottom-style: solid;
  overflow: hidden;

  &:nth-child(odd) {
    border-right-color: lightgrey;
    border-right-style: solid;
    border-right-width: 1px;
  }

  @media (min-width: $tablet-width) {
    @include card-border-around-element;
  }

  .title {

  }

  .value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-color);
    font-weight: bold;
  }

  .positiveDifference {
    color: $green;
    font-weight: bold;
    font-size: large;
  }

  .negativeDifference {
    color: $red;
    font-weight: bold;
    font-size: large;
  }

  .timestamp {

  }
}