@import "../../../../styles/variables";

.ViewFreeFormDocumentScreen {

  .privateIcon {
    color: $red;
    font-weight: bold;
    font-size: large;
    margin-right: 4px;
  }
}