
.SkeletonGifLoader {

  .previewInput {
    width: 100%;
    height: 200px;
    border-radius: 4px;

    > span {
      height: inherit;
    }
  }

}