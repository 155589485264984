@import "../../../styles/variables";

.PreferencesDrawer {

  .title {
    @include border-bottom-element;
    padding: 16px;
    font-size: large;
  }

  .themeContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    .themeTitle {
      font-size: medium;
    }

    .themeSwitch {
      width: 54px;
    }
  }
}