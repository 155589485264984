@import "src/styles/variables";

.modalInput {
  background: transparent !important;

  > input {
    background: transparent;
  }
}


.modalIcon {
  color: var(--dark-home-divider-color);
}

.modalCloseIcon {
  color: var(--primary-text-color);
}

.AuthModal {
  color: var(--primary-text-color);

  .loginButton {
    font-size: 14px;
  }

  .signupButton {
    font-size: 14px;
  }

  .modalTitle {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    padding-top: 16px;
    text-transform: capitalize;
    color: var(--primary-text-color);
  }

  .modalBody {
    padding: 16px;
    text-align: center;
    align-items: center;
    color: var(--primary-text-color);

    .privateBeta {
      padding-bottom: 16px;
    }

    .socialButton {
      width: 200px;
      color: var(--primary-text-color);
      background: transparent;
    }

    .googleButton {
      @extend .socialButton;
    }

    .googleIcon {
      color: $red;
    }

    .twitterIcon {
      color: $blue;
    }

    .facebookIcon {
      color: #3b5998;
    }

    .mailIcon {
      color: gray;
    }

    .twitterButton {
      @extend .socialButton;
    }

    .facebookButton {
      @extend .socialButton;
    }

    .mailButton {
      @extend .socialButton;
    }
  }

}
