@import "../../../styles/variables";

.ViewMetricScreen {

  .graphContainer {
    @include card-border-around-element;

    .header {
      display: flex;
      align-items: center;

      .goBack {
        margin-right: 16px;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .name {
        font-size: 18px;
        flex: 1;
      }
    }
  }
}