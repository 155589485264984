@import "../../styles/variables";

.CardUser {
  @include card-border-bottom-element;
  padding: 16px;
  display: flex;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    @include card-border-around-element;
    margin-top: 16px;
  }

  .imageContainer {

    .image {
      border-radius: 30px;
      height: 60px;
      width: 60px;
      object-fit: cover;
      border-width: 1px;
      border-style: solid;
      border-color: lightgrey;
    }
  }

  .infoContainer {
    flex: 1;
    padding-left: 8px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {

        .displayName {
          font-size: 16px;
          font-weight: 600;
          color: var(--primary-text-color);
        }

        .username {

        }
      }

      .following {
        background: $green;
        border-color: $green;
      }

      .follow {
        font-weight: normal;
      }
    }

    .bio {
      margin-top: 8px;
    }
  }
}