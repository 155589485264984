@import "../../../styles/variables";

.ChatCard {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 16px;
  @include card-border-bottom-element;

  @media (min-width: $tablet-width) {
    @include card-border-around-element;
    margin-top: 16px;
  }

  &:hover {
    cursor: pointer;
    background: var(--main-hover-color);
  }

  .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .infoContainer {
    flex: 1;
    padding-left: 8px;

    .username {
      color: var(--primary-text-color);
      font-weight: bold;
    }

    .subtitle {
      max-width: 200px;
      color: var(--secondary-text-color);
    }
  }
}